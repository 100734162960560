<template>
    <div class="page express">
        <div class="list">
            <div
                class="item"
                v-for="(item, index) in expressData.logisticsTraceDetails"
                :key="index"
            >
                <div class="time">{{ item.timeDesc }}</div>
                <div class="text">{{ item.desc }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { GET_EXPRESS_INFO } from 'api';
export default {
    data() {
        return {
            expressData: {},
        };
    },
    created() {
        this.orderNo = this.$route.query.orderNo;
        this.itemId = this.$route.query.itemId;
        this.getData();
    },
    methods: {
        getData() {
            this.$util
                .get(GET_EXPRESS_INFO, {
                    orderNo: this.orderNo,
                    itemId: this.itemId,
                })
                .then(res => {
                    if (res.success) {
                        res.data.logisticsTraceDetails.reverse();
                        for (let i = 0; i < res.data.logisticsTraceDetails.length; i++) {
                            const item = res.data.logisticsTraceDetails[i];
                            item.timeDesc = moment(item.time).format('YYYY-MM-DD HH:mm:ss');
                        }
                        this.expressData = res.data;
                    }
                });
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.express {
    background: white;
    .list {
        padding: .pxToRem(20) [];
        .item {
            padding-left: .pxToRem(40) [];
            margin-bottom: .pxToRem(40) [];
            .time,
            .text {
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.4);
            }
            &::before {
                content: ' ';
                position: absolute;
                top: .pxToRem(12) [];
                left: 0;
                width: .pxToRem(10) [];
                height: .pxToRem(10) [];
                background: white;
                border-radius: 100%;
                border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
            &::after {
                content: ' ';
                position: absolute;
                top: .pxToRem(20) [];
                bottom: .pxToRem(-50) [];
                left: .pxToRem(5) [];
                border-left: .pxToRem(1) [] dashed rgba(0, 0, 0, 0.3);
                z-index: 0;
            }
            &:first-child {
                .time,
                .text {
                    color: rgba(0, 0, 0, 0.9);
                }
                &::before {
                    content: ' ';
                    border-color: @theme_color_first;
                }
            }
            &:last-child {
                margin-bottom: 0;
                &::after {
                    display: none;
                }
            }
        }
    }
}
</style>
